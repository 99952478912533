import React, { useContext, useEffect } from 'react'
import { motion, useAnimation } from 'framer-motion'

import BowListLazy from "../../_shared/shaping/BowListLazy"
import Typography from "@mui/material/Typography";
import { NotInViewContext } from '../../_shared/AnimateScrollIntoViewport';

const varLetter = ({ notInView }) => {
    return ({
        init: false,
        init2: notInView ? {
            opacity: 1, y: 0, transition: { duration: 0 }
        } : { opacity: 0, y: [ 0, 25, 12 ], transition: { duration: 0.3, delay: notInView ? 0 : 0.1 } }, // false,
        animate: {
            opacity: 1,
            y: 0,
            transition: {
                duration: notInView ? 0 : 0.01,
                delay: notInView ? 0 : 0.1
            },
        },
    }
    )
}

const bLOptionsTitle = {
    itemHeightPx: 100,
    listHeightPx: "auto",
    primarySecondarySeparator: "|",
}

const bowListOptionsAddedListBothBowsLUnderT = {
    bowUnder: true,
    bowUnderOptions: {
        underDeepness: 100,
        bowdirection: "top",
    },
    bothBows: true,
    bowDirection: "left",
    motionDirection: "left",
    listPaddingLeftPx: "16",
}

const styleRightBorders20 = {
    borderTopRightRadius: "20%",
    borderBottomRightRadius: "20%",
}

const styleLeftBorders20 = {
    borderTopLeftRadius: "20%",
    borderBottomLeftRadius: "20%",
}


const sxTitle = {
    "&.title": {
        m: 0,
        py: 0,
        px: [ "2rem", "1.8rem", "1rem" ],
        fontSize: [ "medium", "large", "x-large" ],
        textShadow: (theme) => theme.distinctShadow3,

    },
}

const HomeTitle = ({ title, breakpoint }) => {

    const { notInView } = useContext(NotInViewContext)
    const ctrAnim = useAnimation()
    const styleListFinalXs = { paddingTop: 0, paddingBottom: 0, marginBottom: 0 }

    if ([ "xs", "sm" ].includes(breakpoint)) {
        styleListFinalXs.paddingLeft = 0
        styleListFinalXs.paddingRight = 0
    }

    useEffect(() => {
        const seq = async () => {
            await ctrAnim.start("init2")
            ctrAnim.start("animate")
        }
        if (notInView) {
            ctrAnim.start("animate")
        } else {
            seq()
        }
    }
        , [ notInView, ctrAnim ]
    )

    return (
        <BowListLazy
            name="BL-mainUp-homeTitle"
            options={{
                ...bLOptionsTitle,
                ...bowListOptionsAddedListBothBowsLUnderT,
                nameStyleList: "primary2",
                nameStyleItem: "secondary",
                styleList: {
                    ...styleRightBorders20, ...styleLeftBorders20,
                },
                disableAlignTextToBow: true,
                styleListFinal: styleListFinalXs,
            }}
            key="BL-mainUp-homeTitle"
        >
            {[ <div
                key="BL-mainUp-homeTitle-title"
            >
                <motion.div
                    variants={varLetter({ notInView })}
                    animate={ctrAnim} //"animate"
                    initial="init"
                    className="charInline"
                    key="title-home"
                    style={{ opacity: notInView ? 1 : 0, y: notInView ? 0 : 20, }}
                >
                    <Typography
                        variant="h1"
                        className="title"
                        sx={sxTitle}
                    >
                        {title}
                    </Typography>
                </motion.div>
            </div>,
            ]}
        </BowListLazy>
    )
}

export default HomeTitle
